<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        You have a weak acid with a
        <stemble-latex content="$\text{pK}_\text{a}$" />
        of
        <number-value :value="pKa" unit="." />
        If you prepare a
        <number-value :value="concentration" unit="\text{M}" />
        solution of this acid, determine the
        <stemble-latex content="$\text{pH}$" />
        of the resulting solution?
      </p>

      <calculation-input
        v-model="inputs.pH"
        prepend-text="$\text{pH:}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question143',
  components: {
    StembleLatex,
    CalculationInput,
    NumberValue,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        pH: null,
      },
    };
  },
  computed: {
    concentration() {
      return this.taskState.getValueBySymbol('concentration').content;
    },
    pKa() {
      return this.taskState.getValueBySymbol('pKa').content;
    },
  },
};
</script>
